import Logo from "../assets//logo.png";
import { Link } from "react-router-dom";
import Email from "../assets/cmaths/social/email.png";
import X from "../assets/cmaths/social/x.png";
import Facebook from "../assets/cmaths/social/facebook.png";
import Youtube from "../assets/cmaths/social/youtube.png";
import Phone from "../assets/cmaths/social/phone.png";
import Mail from "../assets/cmaths/social/mail.png";
import { MainContext } from "../utils/context/main-context";
import { useContext } from "react";

function Footer() {
  const { footerRef } = useContext(MainContext);
  return (
    <footer
      ref={footerRef}
      id="footer"
      className="relative pt-8 bg-cmathsDark border-t border-gray-900"
    >
      <div className="relative mb-4">
        <div className="context">
          <div className="grid  grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-y-6 md:gap-x-10">
            <div className="ft-item col-span-2 lg:col-span-1">
              <div className="relative w-44 mx-auto">
                <img src={Logo} alt="Footer Logo" />
              </div>
              <p className="!font-notoSinhala text-xs text-center text-gray-400">
                අද්විතීය ප්‍රථිඵල වාර්තා කරමින්, සහජ දක්ෂයින් අතිවිශිෂ්ටයින් ලෙස
                ඉදිරියට යෑමට, ගැඹුරු විෂය කරුණු පවා ඉතාමත් සරලව හා තර්කානුකූලව,
                මනසට කා වදින ලෙස ඉගෙන ගැනීමට එන්න අප හා එක්වන්න.
              </p>
            </div>
            <div className="ft-item">
              <div className="ft-title">
                <h6>Useful Links</h6>
              </div>
              <div className="ft-info">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/courses">Subjects</Link>
                  </li>
                  <li>
                    <Link to="/institutes">Institutes</Link>
                  </li>
                  <li>
                    <a href="https://support.sailingpen.lk">Support</a>
                  </li>
                
                  <li>
                    <Link to="/refund-policy">Refund Policy</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="ft-item">
              <div className="ft-title">
                <h6>Social Media</h6>
              </div>
              <div className="ft-info">
                <ul>
                  <li>
                    <a href="mailto:sailingpenpvtltd@gmail.com">
                      <img src={Email} alt="" />
                      <span>Email</span>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/combinedmathslk">
                      <img src={X} alt="" />
                      <span>X</span>
                    </a>
                  </li>
                  <li>
                    <a href="https://web.facebook.com/combinedmaths.lk">
                      <img src={Facebook} alt="" />
                      <span>Facebook</span>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.youtube.com/@ruwan_darshana">
                      <img src={Youtube} alt="" />
                      <span>Youtube</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="ft-item col-span-2 md:col-span-1">
              <div className="ft-title">
                <h6>Contact Info</h6>
              </div>
              <div className="ft-info">
                <ul>
                  <li>
                    <p>
                      <img src={Mail} alt="" />{" "}
                      <span>24, Papiliyana Rd, Gangodavila, Nugegoda</span>
                    </p>
                  </li>
                  <li>
                    <a href="tel:+94706818110">
                      <img src={Phone} alt="" />
                      <span>070 681 81 10</span>
                    </a>
                  </li>
                  <li>
                    <a href="tel:+94706818111">
                      <img src={Phone} alt="" />
                      <span>070 681 81 11</span>
                    </a>
                  </li>
                  <li>
                    <a href="tel:+94706818112">
                      <img src={Phone} alt="" />
                      <span>070 681 81 12</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="relative py-4 bg-cmathsDark border-t border-gray-900">
        <div className="context">
          <div className="relative flex flex-wrap justify-center md:justify-between space-y-2">
            <div className="flex-initial">
              <p className="text-sm font-medium text-white">
                Copyright © 2024{" "}
                <a href="/" className="text-cmathsAccent">
                  Combinedmaths.lk
                </a>{" "}
                All rights reserved.
              </p>
            </div>
            <div className="flex-initial">
              <p className="text-sm text-white">
                
                <Link to="/privacy-policy" className="text-gray-400 hover:text-cmathsPrimary transition-all duration-300 ease-in-out">
                  Privacy Policy
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
