import { createContext, ReactNode, useRef, createRef, RefObject, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

interface MainContextType {
  footerRef: RefObject<HTMLDivElement>;
}

const MainContext = createContext<MainContextType>({
  footerRef: createRef(),
});

const MainProvider = ({ children }: { children: ReactNode }) => {
  const footerRef = useRef<HTMLDivElement | null>(null);
  
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <MainContext.Provider value={{ footerRef }}>
      {children}
    </MainContext.Provider>
  );
};

export { MainProvider, MainContext };
