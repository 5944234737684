import React from "react";
import { NavLink } from "react-router-dom";

interface NavigationLinkProps {
  to: string;

  children: React.ReactNode;
}

const NavigationLink: React.FC<NavigationLinkProps> = ({
  to,

  children,
}) => {
  return (
    <NavLink
      end
      to={to}
      className={(_) =>
        _.isActive
          ? "text-white bg-cmathsPrimary"
          : "text-cmathsDark hover:text-cmathsPrimary hover:bg-gray-100"
      }
    >
      {children}
    </NavLink>
  );
};

export default NavigationLink;
