import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { InfoProvider } from "./utils/context/info-context";
import { ThemeProvider } from "@material-tailwind/react";
import { BrowserRouter } from "react-router-dom";
import "material-react-toastify/dist/ReactToastify.css";
import "reactjs-popup/dist/index.css";
import "./index.css";
import { AuthProvider } from "./utils/context/auth-context";
import { CartProvider } from "./utils/context/cart-context";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GQL_URL,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("_chuck-38e6e39a-ab69-4aac-bb0c");
  return {
    headers: {
      ...headers,
      authorization: token ? token : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <ThemeProvider>
        <InfoProvider>
          <AuthProvider>
            <CartProvider>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </CartProvider>
          </AuthProvider>
        </InfoProvider>
      </ThemeProvider>
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
