import { Outlet } from "react-router-dom";
import Footer from "./footer";
import TopNav from "./top-nav";
import { MainProvider } from "../utils/context/main-context";
import BottomBar from "./bottom-bar";

function HomeLayout() {
  return (
    <>
      <MainProvider>
        <BottomBar />
        <TopNav />
        <main className="cmaths-main">
          <Outlet />
        </main>
        <Footer />
      </MainProvider>
    </>
  );
}

export default HomeLayout;
