import { Link, NavLink } from "react-router-dom";
import useAuth from "../utils/context/auth-context";
import useInfo from "../utils/context/info-context";
import Avatar from "react-avatar";
import { NavActionProps } from "../utils/types/types";
import { appendSizeToImageUrl } from "../lib";

function NavAuth({ openNav, setOpenNav, mobile, className }: NavActionProps) {
  const { auth, ...user } = useAuth();
  const { storageBucketImageRoot } = useInfo();

  return (
    <>
      <div className={`items-center gap-x-4 lg:gap-x-6 ${className ?? ""}`}>
        {auth ? (
          <>
            <NavLink
              to="/profile"
              className={"flex items-center"}
              onClick={() => setOpenNav(openNav)}
            >
              {user.profile?.avatar_url ? (
                <img
                  src={`https://api.combinedmaths.lk/${user.profile?.avatar_url_new}`}
                  alt={user.fname}
                  className="mx-auto rounded-full w-10 h-10"
                />
              ) : (
                <Avatar
                  name={user.fname + " " + user.lname}
                  color="#1A76D1"
                  size="40"
                  round={true}
                  className="mx-auto !w-10 !h-10"
                />
              )}
              <h4
                className={`font-medium capitalize mx-2 truncate max-w-[5rem] md:max-w-max text-sm md:text-base leading-none text-white`}
              >
                {user.fname} {user.lname}
              </h4>
            </NavLink>
          </>
        ) : (
          <>
            <Link
              className={`transition-all text-sm md:text-base duration-300 ease-in-out font-medium hover:text-cmathsAccent ${
                mobile ? "text-white" : "text-cmathsDark "
              }`}
              to="/auth/register"
            >
              Register
            </Link>
            <Link className="btn btn-md btn-green" to="/auth/login">
              Login
            </Link>
          </>
        )}
      </div>
    </>
  );
}

export default NavAuth;
